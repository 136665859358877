import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
  IconButton,
} from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { invokeApig } from '../lib/awsLib';

const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    marginRight: theme.spacing(2),
  },
  processButton: {
    height: 40,
    marginTop: theme.spacing(2),
  },
  ufInfo: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  serviceRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  comparative: {
    backgroundColor: theme.palette.grey[50],
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  diffPositive: {
    color: theme.palette.success.main,
  },
  diffNegative: {
    color: theme.palette.error.main,
  },
  headerCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
}));

const ServiceRow = ({ service, classes }) => {
  const [open, setOpen] = useState(false);
  const diffQuantity = service.quantity - service.comparative.quantity;
  const diffTotal = service.total - service.comparative.total;

  return (
    <>
      <TableRow className={classes.serviceRow}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{service.name}</TableCell>
        <TableCell align="right">{service.quantity}</TableCell>
        <TableCell>{service.currency}</TableCell>
        <TableCell align="right">
          {service.price.toLocaleString('es-CL', { minimumFractionDigits: 2 })}
        </TableCell>
        <TableCell align="right">
          {service.total.toLocaleString('es-CL', { minimumFractionDigits: 2 })}
        </TableCell>
      </TableRow>
      <TableRow className={classes.comparative}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle2" gutterBottom>
                Comparación con mes anterior
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Período</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">Diferencia Cantidad</TableCell>
                    <TableCell align="right">Diferencia Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Mes anterior</TableCell>
                    <TableCell align="right">{service.comparative.quantity}</TableCell>
                    <TableCell align="right">
                      {service.comparative.total.toLocaleString('es-CL', { minimumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell 
                      align="right"
                      className={diffQuantity > 0 ? classes.diffPositive : classes.diffNegative}
                    >
                      {diffQuantity > 0 ? '+' : ''}{diffQuantity}
                    </TableCell>
                    <TableCell 
                      align="right"
                      className={diffTotal > 0 ? classes.diffPositive : classes.diffNegative}
                    >
                      {diffTotal > 0 ? '+' : ''}
                      {diffTotal.toLocaleString('es-CL', { minimumFractionDigits: 2 })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ClientRow = ({ client, classes }) => {
  const [open, setOpen] = useState(false);
  
  // Calcula el total actual del cliente sumando todos los servicios
  const currentTotal = client.services.reduce((sum, service) => sum + service.total, 0);
  
  // Calcula el total anterior del cliente sumando las comparativas
  const previousTotal = client.services.reduce((sum, service) => sum + service.comparative.total, 0);
  
  // Calcula la diferencia
  const totalDiff = currentTotal - previousTotal;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{client.id}</TableCell>
        <TableCell>{client.name}</TableCell>
        <TableCell align="right">
          {currentTotal.toLocaleString('es-CL', { 
            style: 'currency', 
            currency: 'CLP'
          })}
          <Typography 
            variant="caption" 
            display="block"
            className={totalDiff > 0 ? classes.diffPositive : classes.diffNegative}
          >
            ({totalDiff > 0 ? '+' : ''}{totalDiff.toLocaleString('es-CL', { 
              style: 'currency', 
              currency: 'CLP'
            })})
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Servicios
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Servicio</TableCell>
                    <TableCell align="right">Cantidad</TableCell>
                    <TableCell>Moneda</TableCell>
                    <TableCell align="right">Precio</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {client.services.map((service, index) => (
                    <ServiceRow key={index} service={service} classes={classes} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const POLLING_INTERVAL = 30000; // 30 segundos
const STATUS = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR'
};

const ClientProcessPage = () => {
  const classes = useStyles();
  const currentDate = moment();
  
  const [selectedMonth, setSelectedMonth] = useState(currentDate.month());
  const [selectedYear, setSelectedYear] = useState(currentDate.year());
  const [isProcessing, setIsProcessing] = useState(false);
  const [processData, setProcessData] = useState(null);
  const [error, setError] = useState(null);
  const [processId, setProcessId] = useState(null);
  const [pollingInterval, setPollingInterval] = useState(null);

  // Función para consultar el estado del proceso
  const checkProcessStatus = useCallback(async (id) => {
    try {
      const response = await invokeApig({
        path: `/clients/process/status/${id}`,
        method: "GET"
      });

      if (!response || response.code !== 0) {
        throw new Error(response?.message || 'Error al consultar el estado del proceso');
      }

      switch (response.status) {
        case STATUS.COMPLETED:
          // Proceso completado, detener polling y mostrar resultados
          setProcessData(response.data);
          setIsProcessing(false);
          setProcessId(null);
          if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
          }
          break;

        case STATUS.ERROR:
          // Error en el proceso, detener polling y mostrar error
          setError(response.message || 'Error en el procesamiento');
          setIsProcessing(false);
          setProcessId(null);
          if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
          }
          break;

        case STATUS.PROCESSING:
          // Proceso aún en ejecución, continuar polling
          break;

        default:
          throw new Error('Estado de proceso desconocido');
      }
    } catch (error) {
      console.error('Error checking process status:', error);
      setError('Error al consultar el estado del proceso. Por favor intente nuevamente.');
      setIsProcessing(false);
      setProcessId(null);
      if (pollingInterval) {
        clearInterval(pollingInterval);
        setPollingInterval(null);
      }
    }
  }, [pollingInterval]);

  // Limpiar el intervalo cuando el componente se desmonte
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  // Función para iniciar el proceso
  const handleStartProcess = async () => {
    try {
      setIsProcessing(true);
      setError(null);
      setProcessData(null);
      
      // Primer llamado para iniciar el proceso
      const response = await invokeApig({
        path: "/clients/process/start",
        method: "POST",
        body: {
          month: selectedMonth + 1,
          year: selectedYear
        }
      });

      if (!response || response.code !== 0) {
        throw new Error(response?.message || 'Error al iniciar el proceso');
      }

      // Guardar el ID del proceso
      setProcessId(response.processId);

      // Iniciar el polling
      const interval = setInterval(() => {
        checkProcessStatus(response.processId);
      }, POLLING_INTERVAL);

      setPollingInterval(interval);

      // Primera consulta inmediata del estado
      await checkProcessStatus(response.processId);

    } catch (error) {
      console.error('Error starting process:', error);
      setError('Error al iniciar el proceso. Por favor intente nuevamente.');
      setIsProcessing(false);
    }
  };

  // Función para cancelar el proceso
  const handleCancelProcess = useCallback(() => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
    setIsProcessing(false);
    setProcessId(null);
    setError('Proceso cancelado por el usuario');
  }, [pollingInterval]);

  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} lg={10}>
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Proceso de Clientes
          </Typography>

          <Grid container alignItems="flex-start" spacing={2}>
            {/* Los selectores de mes y año se mantienen igual */}
            
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                className={classes.processButton}
                onClick={handleStartProcess}
                disabled={isProcessing}
                fullWidth
              >
                {isProcessing ? (
                  <>
                    <CircularProgress size={24} color="inherit" />
                    &nbsp;Procesando...
                  </>
                ) : (
                  'Iniciar proceso'
                )}
              </Button>
              {isProcessing && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancelProcess}
                  className={classes.cancelButton}
                  fullWidth
                  style={{ marginTop: '8px' }}
                >
                  Cancelar proceso
                </Button>
              )}
            </Grid>
          </Grid>

          {error && (
            <Alert severity="error" style={{ marginTop: 16 }}>
              {error}
            </Alert>
          )}

          {isProcessing && (
            <Alert severity="info" style={{ marginTop: 16 }}>
              Proceso en ejecución. ID: {processId}
              <LinearProgress style={{ marginTop: 8 }} />
            </Alert>
          )}  

          {processData && (
            <>
              <Paper className={classes.ufInfo}>
                <Typography variant="subtitle1">
                  Información UF
                </Typography>
                <Typography variant="body1">
                  Día: {processData.ufDay}
                </Typography>
                <Typography variant="body1">
                  Valor: {processData.ufValue.toLocaleString('es-CL', { 
                    style: 'currency', 
                    currency: 'CLP'
                  })}
                </Typography>
              </Paper>

              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.headerCell} />
                      <TableCell className={classes.headerCell}>RUT</TableCell>
                      <TableCell className={classes.headerCell}>Nombre</TableCell>
                      <TableCell className={classes.headerCell} align="right">Total Facturación</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processData.clients.map((client, index) => (
                      <ClientRow key={index} client={client} classes={classes} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ClientProcessPage;